const hostUrl = "https://stgadminapi.realfantasyteams.com/adminService"
const authHostUrl = "https://stguser.realfantasyteams.com/rft-accounts"
const sffHostUrl = "https://stgsff.realfantasyteams.com/sffService"

export const environment = {
  production: true,
  hostUrl: hostUrl,
  sffHostUrl: sffHostUrl,
  openId: {
    accountsUrl: authHostUrl,
    backendUrl:hostUrl,
    clientId: 'GCMUDiuY5a7WvyUNt9n3QzTjst57bf4I',
    clientSecret:'Jsjw3hsH5WIcS5niwshi5tn67Gt4ghe',
    redirect_url: `${window.location.origin}/auth/redirect`,
    response_type: 'code',
    opUrl: `${authHostUrl}/auth/authorize`,
    opMobileUrl: `${authHostUrl}/auth/openIdLogin/login`,
    scope: 'openid',
    tokenEndpoint: `${authHostUrl}/auth/openIdLogin/token`,
    logoutEndpoint:`${authHostUrl}/auth/openIdLogin/logout`,
  },
  currentSeason: 2024,
  seasonList: [2025, 2024, 2023, 2022, 2021, 2020]
}
